import { ProjectConfig } from '@contember/admin'

export const routes: ProjectConfig['routes'] = {
	dashboard: { path: '/' },

	pageList: { path: '/pages' },
	pageCreate: { path: '/pages/new' },
	pageEdit: { path: '/pages/:id' },

	subscriberList: { path: '/subscribers' },
	subscriberCreate: { path: '/subscribers/new' },
	subscriberEdit: { path: '/subscribers/:id' },

	messageList: { path: '/messages' },
	messageCreate: { path: '/messages/new' },
	messageEdit: { path: '/messages/:id' },

	eventList: { path: '/events' },
	eventCreate: { path: '/events/new' },
	eventCopy: { path: '/events/copy/:id' },
	eventEdit: { path: '/events/:id' },

	eventDuplicateList: { path: '/duplicate-events' },
	eventDuplicateEdit: { path: '/duplicate-events/:id' },
	eventDuplicateMove: { path: '/duplicate-events/:id/move' },

	locales: { path: '/locales' },
	headerMenu: { path: '/menu' },
	social: { path: '/social' },
	redirects: { path: '/redirects' },
	options: { path: '/options/:type' },

	tenantChangePassword: { path: '/change-password' },
	tenantUsers: { path: '/users' },
	tenantInviteUser: { path: '/invite-user' },
	tenantEditUser: { path: '/edit-user/:id' },
}
